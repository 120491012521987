.project {
    display: flex;
    flex-direction: row;
    padding: 2rem 0 6rem 0;
    width: 100%;
}

.project__background_figure {
    background: content-box var(--navy);
}

.project__background_img {
    opacity: 0.7;
    max-width: 1100px;
    width: 100%;
    box-shadow: 5px 5px 5px rgb(1, 1, 33);
    border-radius: var(--border-radius);
    transition: all 0.5s ease;
}

.project__background_img:hover {
    opacity: 1;
    max-width: 1200px;
}

.project__cntr_odd,
.project__cntr_even {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.project__cntr_odd {
    align-items: flex-end;
}

.project__featured_project {
    font-family: var(--fonts2);
    font-style: italic;
    color: var(--aqua);
}

.project__title {
    font-size: var(--fontL);
    padding: 0.5rem 1rem 2rem 1rem;
    white-space: nowrap;
    min-width: 18rem;
}

.project__title_link {
    text-decoration: none;
    color: white;
    text-decoration: underline;
}

.project__title_link:hover {
    text-decoration: none;
    color: rgb(3, 255, 230);
    text-decoration: underline;
}

.project__details {
    display: flex;
    flex-direction: column;
}

.project__desc {
    background-color: var(--lighternavy);
    padding: 1.5rem 1.2rem;
    position: relative;
    width: 120%;
    line-height: 1.6rem;
    font-weight: 300;
    box-shadow: 5px 5px 5px rgb(1, 1, 33);
    border-radius: var(--border-radius);
    z-index: 3;
    text-align: justify;
    text-justify: inter-word;
}

.project__desc_odd {
    align-self: flex-end;
}

.project__technologies {
    display: flex;
    justify-content: flex-start;
    width: 120%;
    flex-wrap: wrap;
    padding: 2rem 0 0 0;
}

.project__technologies_odd {
    justify-content: end;
    align-self: flex-end;
}

.project__technology {
    padding: 0.25rem 0.4rem;
    white-space: nowrap;
    font-size: 0.9rem;
}

.project__technology_empty {
    visibility: hidden
}

.projects__linkIcons {
    display: flex;
    align-self: flex-end;
}

.projects__icon_wrapper {
    list-style: none;
}

.projects__linkIcons_even {
    align-self: flex-start;
}

.project__icon {
    list-style: none;
    margin: 0.5rem 0.7rem;
    display: inline-block;
    width: 25px;
    height: 25px;
    background-size: cover;
}

.project__icon_github {
    background-image: url(../../../assets/app_logo/github.svg);
}

.project__icon_github:hover,
.project__icon_github:focus {
    background-image: url(../../../assets/app_logo/github-colored.svg);
}

.project__icon_external_link {
    background-image: url(../../../assets/app_logo/external-link.svg);
}

.project__icon_external_link:hover,
.project__icon_external_link:focus {
    background-image: url(../../../assets/app_logo/external-link-colored.svg);
}


@media screen and (max-width: 1024px) {

    .project__technology,
    .project__technology_empty {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 768px) {
    .project {
        flex-direction: column;
        align-items: center;
        padding: 2rem 0 32rem 0;
        position: relative;
        margin-bottom: 4rem;
    }

    .project__background {
        display: flex;
        align-items: center;
        position: absolute;
        top: 7rem;
        width: 90%;
    }

    .project__background_img {
        opacity: 1;
    }

    .project__cntr_odd {
        align-items: flex-start;
    }

    .project__details {
        position: absolute;
        top: 17rem;
        z-index: 10;
        background-color: rgba(46, 61, 146, 0.7);
        border-radius: var(--border-radius);
        box-shadow: 5px 5px 5px rgb(1, 1, 33);
        width: 100%;
        margin: auto;
        padding: 1rem 0.8rem 0 1.2rem;

    }

    .project__desc {
        width: 100%;
        padding: 0;
        box-shadow: 0 0 0;
        font-size: var(--fontXS);
        background-color: transparent;
    }

    .project__technology,
    .project__technology_empty {
        padding: 0.1rem 0.5rem;
    }

    .project__technologies {
        width: 100%;
    }

    .project__technologies_odd {
        align-self: flex-start;
    }

    .project__technologies_even {
        justify-content: end;
    }

    .projects__linkIcons_even {
        align-self: flex-end;
    }

}

@media screen and (max-width: 550px) {
    .project {
        padding: 2rem 0 31rem 0;
    }

    .project__details {
        top: 11rem;
    }

    .project__technology,
    .project__technology_empty {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 480px) {
    .project {
        padding: 2rem 0 34rem 0;
    }

    .project__details {
        top: 12rem;
    }
}