.position {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4rem 0 2rem 0;
    width: 100%;
}

.position__logo {
    border-radius: var(--border-radius);
    max-width: 8rem;
    max-height: 8rem;
    margin-top: 0.4rem;
}

.position__cntr {
    display: flex;
    align-items: center;
    padding-bottom: 4rem;
}

.position__company {
    max-width: 18rem;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;

}

.position__company_name {
    font-size: var(--fontL);
    padding-bottom: 0.4rem;
    white-space: nowrap;
    min-width: 18rem;
}

.position__job_title {
    font-family: var(--fonts2);
    font-size: var(--fontM);
    font-style: italic;
    font-weight: bold;
    color: var(--aqua);
    padding-bottom: 0.3rem;
}

.position__dates {
    font-size: var(--fontXS);
    font-weight: lighter;
}

.position__tasks {
    width: 35rem;
    line-height: 1.6rem;
    font-weight: 300;
    border-radius: var(--border-radius);
    margin-top: 0.4rem;
}

.position__desc {
    background-color: var(--lighternavy);
    padding: 1rem 2rem 1rem 3rem;
    border-radius: var(--border-radius);
}

.position__techonologies {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 0rem 1rem 0rem;
    font-weight: lighter;
}

.position__techonology {
    padding-right: 1rem;
    font-size: 0.9rem;

}

.position__techonology:last-child {
    padding-right: 0rem;
}


@media screen and (max-width: 900px) {

    .position {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .position__cntr {
        padding-bottom: 0;
    }

    .position__company_name {
        min-width: 10rem;
    }

    .position__tasks {
        max-width: 35rem;
        width: 100%;
        margin-top: 1.5rem;
    }

    .position__desc {
        padding: 1rem 1rem 1rem 2rem;
    }
}