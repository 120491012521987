.navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    padding: 0;
    background-color: var(--navy);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    transition: all 1s ease-out;
    border: solid white;
    border-width: 0 0 1px 0;
}

.navbar__left {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.navbar__logo {
    width: 4rem;
    margin-left: 2rem;
}

.navbar__title {
    font-size: 3rem;
    padding-left: 2rem;
    font-weight: 300;
    white-space: nowrap;
    text-align: center;
}

.navbar__title:hover {
    font-weight: 400;
}

.navbar__right {
    display: flex;
    justify-content: space-between;
}

.navbar__languages {
    display: flex;
    flex-direction: column;
    justify-content: center;

    /* padding-top: 0.5rem; */
}

.hamburger_cntr {
    display: none;
    cursor: pointer;
}

@media screen and (max-width: 1540px) {
    .navbar__title {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .hamburger_cntr {
        display: block;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .hamburger,
    .hamburger_active {
        display: block;
        position: absolute;
        right: 7rem;
        left: 7rem;
    }

    .bar {
        display: block;
        width: 25px;
        height: 3px;
        margin: 5px auto;
        transition: all 0.3s ease-in-out;
        background-color: white;
    }

    .hamburger_active span:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger_active span:nth-child(2) {
        opacity: 0;
    }

    .hamburger_active span:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .navbar__empty {
        display: none;

    }

    .navbar__empty_ham_active {
        height: 100vh;
    }
}


@media screen and (max-width: 768px) {
    .navbar {
        padding: 0;
    }

    .navbar__left {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .navbar__logo {
        width: 3rem;
        /* margin-left: 0; */
    }

    .navbar__title {
        font-size: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0;
        padding-bottom: 0.2rem;
        z-index: 55;
    }

    .navbar__languages {
        display: flex;
        flex-direction: column;
        /* padding-top: 0.75rem; */
    }


}