.about {
    padding-left: 8rem;
    overflow: hidden;
}

.about__title_cntr {
    display: flex;
}

.about__line {
    background-color: var(--blue);
}

.about__title {
    color: var(--blue);
}

.about__content_cntr {
    display: flex;
    justify-content: space-around;
}

.about__desc_el {
    padding-bottom: 1rem;
    line-height: 1.5rem;
}

.about__technologies_title {
    font-size: var(--fontM);
    font-weight: 300;
    margin: 2rem 0 1rem 0;
}

.about__technologies_cntr {
    display: flex;
}

.about__attribute_ul {
    width: 100%;
    list-style-image: url('../../assets/list_markers/check-circled-outline.svg');
    text-align: left;
    text-justify: auto;
}

.about__attribute_left_col {
    padding-left: 2rem;
}

.about__attribute_right_col {
    padding-left: 0.5rem;
}

.about__attribute_el_li {
    line-height: 1.8rem;
    font-size: var(--fontXS);
    font-family: var(--fonts2);
    transition: all 0.1s ease-in-out;
}

.about__attribute_el_li:hover {
    color: rgb(149, 179, 235);
    list-style-image: url('../../assets/list_markers/check-circled-outline-big.svg');
}

.about__img_wrapper {
    padding-left: 3rem;
    margin: 1rem 0;
}

.about__img_cntr,
.about__img,
.about__img_frame,
.about__img_background {
    width: 300px;
    height: 300px;
    border-radius: var(--border-radius);
}

.about__img_cntr {
    background: content-box linear-gradient(to top left, var(--navy), var(--blue));
    position: relative;
}

.about__img {
    opacity: 0.85;
    position: absolute;
    left: -1rem;
    top: -1rem;
    z-index: 3;
}

.about__img:hover {
    opacity: 1;
}

.about__img_frame {
    border: 4px solid var(--blue);
    z-index: 1;
}

.about__img_background {
    position: absolute;
    left: -1rem;
    top: -1rem;
    background-color: var(--blue);
    z-index: 2;
}

@media screen and (max-width: 1200px) {
    .about__technologies_cntr {
        margin-bottom: 2rem;
        flex-direction: column;
    }

    .about__attribute_right_col {
        padding-left: 2rem;
    }

    .about__attribute_el_li {
        white-space: nowrap;
    }
}

@media screen and (max-width: 1080px) {
    .about__desc_el {
        padding-bottom: 0;
    }

    .about__attribute_el_li:hover {
        color: white;
        list-style-image: url('../../assets/list_markers/check-circled-outline.svg');
    }

    .about__img_wrapper {
        padding-left: 2rem;
    }

    .about__img_cntr,
    .about__img,
    .about__img_frame,
    .about__img_background {
        width: 250px;
        height: 250px;
    }

    .about__img {
        opacity: 1;
    }
}

@media screen and (max-width: 900px) {

    .about__img_cntr,
    .about__img,
    .about__img_frame,
    .about__img_background {
        width: 220px;
        height: 220px;
    }
}


@media screen and (max-width: 768px) {
    .about {
        padding-left: 5rem;
    }

    .about__content_cntr {
        flex-direction: column;
        align-items: center;
    }

    .about__technologies_title {
        font-size: var(--fontS);
    }

    .about__img_cntr,
    .about__img,
    .about__img_frame,
    .about__img_background {
        width: 300px;
        height: 300px;
    }

    .about__attribute_el_li {
        white-space: normal;
    }
}

@media screen and (max-width: 480px) {
    .about {
        padding-left: 2rem;
    }

    .about__technologies_cntr {
        padding-left: 0rem;
    }

    .about__img_cntr,
    .about__img,
    .about__img_frame,
    .about__img_background {
        width: 250px;
        height: 250px;
    }
}