.footer {
    color: white;
    width: 100%;
    text-align: center;
    padding: 0rem 0rem 0.7rem 0;
    font-weight: 200;
    font-size: 0.8rem;
    background-color: var(--navy);
    display: flex;
    justify-content: center;
}

.footer_link {
    display: flex;
    text-decoration: none;
    color: white;
}

.footer_logo {
    margin: 0 0.25rem;
}