.projects__title_cntr {
    display: flex;
}

.projects__line {
    background-color: var(--blue);
}

.projects__title {
    color: var(--blue);
}