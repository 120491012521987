* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--navy);
  font-family: 'Exo 2';
  color: white;
}

:root {
  --navy: rgb(10, 10, 50);
  --lighternavy: rgb(30, 30, 100);
  --shadowednavy: rgb(109, 109, 143);

  --blue: cornflowerblue;
  --aqua: turquoise;
  --light-blue: rgba(0, 216, 255, 1);
  --aqua-transparent: rgba(64, 224, 208, 0.127);
  --pink: hotpink;
  --gold: rgb(236, 204, 158);

  --border-radius: 5px;

  --fonts2: Inconsolata;
  --fontXS: 1rem;
  --fontS: 1.1rem;
  --fontM: 1.2rem;
  --fontL: 2rem;
}

.component {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  padding: 10rem 8rem 0 8rem;
}

.title {
  font-size: var(--fontL);
  padding-bottom: 1rem;
  white-space: nowrap;
}

.title:hover {
  font-weight: bold;
}

.content {
  font-size: var(--fontM);
  font-weight: 300;
  text-align: justify;
}

.vertical_line {
  width: 1px;
  height: 10rem;
  background: white;
}

.horizontal_line {
  max-height: 1px;
  height: 1px;
}

.horizontal_line_before {
  width: 4rem;
  margin-right: 1rem;
  margin-top: 1.3rem;
}

.horizontal_line_after {
  width: 16rem;
  margin-left: 1rem;
  margin-top: 1.3rem;
}

a:visited {
  color: white;
}

@media screen and (max-width: 1024px) {
  .content {
    font-size: var(--fontS);
  }
}

@media screen and (max-width: 768px) {
  .component {
    padding: 18rem 5rem 0 5rem;
  }

  .content {
    text-align: start;
  }

  .vertical_line {
    width: 1px;
    height: 5rem;
  }
}

@media screen and (max-width: 480px) {
  .component {
    padding: 6rem 2rem 6rem 2rem;
  }
}